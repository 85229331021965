/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import CustomLink from 'components/shared/CustomLink'

// Elements
import ButtonPhone from 'components/elements/ButtonPhone'

import Social from 'components/Social'

// Language
import { getLanguage } from 'services/language'

// Icons
import next from 'img/next.svg'
import logo from 'img/logo-solely.svg'

const StyledFooter = styled.div``

const Top = styled.div`
  background-color: ${(props) => props.theme.color.light};

  & > div {
    overflow: hidden;
  }
`

const Logo = styled.img`
  width: 200px;
  height: 38px;
`

const Slogan = styled.h3`
  font-family: ${(props) => props.theme.font.family.secondary};
  font-size: ${(props) => props.theme.font.size.sm};
  font-weight: ${(props) => props.theme.font.weight.l};
  color: ${(props) => props.theme.color.text.secondary};
  max-width: 120px;
  position: relative;

  @media (min-width: 992px) {
    &:after {
      background-color: ${(props) => props.theme.color.contrast};
      content: '';
      position: absolute;
      left: 105%;
      height: 1px;
      width: 3000px;
    }
  }
`

const Bottom = styled.div`
  background-color: ${(props) => props.theme.color.secondary};
  color: ${(props) => props.theme.color.text.secondary};
`

const Title = styled.h4`
  font-size: ${(props) => props.theme.font.size.xsm};
`

const Content = styled.div`
  font-size: ${(props) => props.theme.font.size.sm};
  line-height: ${(props) => props.theme.font.size.l};

  & a {
    color: ${(props) => props.theme.color.text.secondary};

    &:hover {
      text-decoration: underline;
    }
  }
`

const Anchor = css`
  display: block;
  margin-bottom: 10px;
`

const Link = styled(CustomLink)`
  ${Anchor};
`

const Download = styled.a`
  ${Anchor};
  display: flex;
  align-items: center;
`

const DownloadIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 10px;
`

const BottomLine = styled.div`
  background-color: ${(props) => props.theme.color.text.main};
  height: 1px;
  width: 100%;
  margin-right: 15px;
`

const PolicyLink = styled.a`
  font-family: ${(props) => props.theme.font.family.main};
  font-size: ${(props) => props.theme.font.size.s};
  color: ${(props) => props.theme.color.text.main};
  display: block;
  min-width: 100px;
  text-align: right;
  white-space: nowrap;
  margin-left: 4px;
`

const Footer = () => {
  const { footers, contact, downloads } = useStaticQuery(graphql`
    {
      footers: allWordpressWpComponenten(
        filter: { wordpress_id: { in: [117, 473] } }
      ) {
        edges {
          node {
            wpId: wordpress_id
            acf {
              topSlogan: top_slogan
              menu {
                title
                links {
                  wpId: wordpress_id
                  title
                  path
                }
              }
              contact {
                title
              }
              downloads {
                title
                files
              }
              privacy {
                link {
                  title
                  url {
                    localFile {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      contact: allWordpressPage(filter: { wordpress_id: { in: [9, 439] } }) {
        edges {
          node {
            acf {
              streetname
              housenumber
              zipcode
              phonenumber
              mobilePhone: mobile_phone
              city
              emailAddress: email_address
            }
            locale: wpml_current_locale
          }
        }
      }
      downloads: allWordpressWpDownloadFiles {
        edges {
          node {
            wpId: wordpress_id
            title
            acf {
              file {
                url {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const language = getLanguage()

  const footer = footers.edges.find(
    ({ node: { wpId } }) => wpId === (language === 'nl_NL' ? 117 : 473)
  )
  const info = contact.edges.find(({ node: { locale } }) => locale === language)

  return (
    <StyledFooter>
      <Top>
        <div className="container py-4 d-md-flex align-items-center">
          <Logo src={logo} alt="" />
          <Slogan className="ml-md-4 mt-1 mt-md-0">
            {footer.node.acf.topSlogan}
          </Slogan>
        </div>
      </Top>
      <Bottom>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-6">
              <Title>{footer.node.acf.contact.title}</Title>
              <Content className="mt-4">
                {info.node.acf.streetname} {info.node.acf.housenumber}
                <br />
                {info.node.acf.zipcode}
                <br />
                {info.node.acf.city}
                <br />
                <br />
                <div>
                  <a
                    href={`mailto:${info.node.acf.emailAddress}`}
                    className="d-block"
                  >
                    {info.node.acf.emailAddress}
                  </a>
                  <ButtonPhone
                    className="mt-3"
                    isAnchor
                    to={`tel:${info.node.acf.phonenumber}`}
                  >
                    {info.node.acf.phonenumber}
                  </ButtonPhone>

                  <p className="mt-4">
                    WelbijWim is mede een handelsnaam van Wim Muijs Financieel
                    advies.
                  </p>
                </div>
              </Content>
            </div>
            <div className="col-lg-3 mt-5 mt-lg-0">
              <Title>{footer.node.acf.menu.title}</Title>
              <Content className="mt-4">
                {footer.node.acf.menu.links.map(({ wpId, title, path }) => (
                  <Link key={wpId} to={path}>
                    {title}
                  </Link>
                ))}
              </Content>
            </div>
            <div className="col-lg-3 mt-5 mt-lg-0">
              <Title>{footer.node.acf.downloads.title}</Title>
              <Content className="mt-4">
                {footer.node.acf.downloads.files.map((fileId) => {
                  const download = downloads.edges.find(
                    ({ node: { wpId } }) => wpId === fileId
                  )

                  return (
                    <Download
                      className=""
                      key={download.node.wpId}
                      href={download.node.acf.file.url.localFile.publicURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <DownloadIcon src={next} alt="" />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: download.node.title,
                        }}
                      />
                    </Download>
                  )
                })}
              </Content>
            </div>
          </div>
          <div className="mt-4 d-flex align-items-center justify-content-between">
            <Social />
            <BottomLine />
            <div className="d-flex gap-2">
              {footer.node.acf.privacy.map(
                ({ link: { title, url } }, index) => (
                  
                  <PolicyLink
                  // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    href={url.localFile.url}
                    target="_blank"
                  >
                    {title}
                  </PolicyLink>
                )
              )}
            </div>
          </div>
        </div>
      </Bottom>
    </StyledFooter>
  )
}

export default Footer
